import React from "react"

const Historias = () => {
    return (
    <section id = "telesan-hero2">
        <div className = "telesan-row">
                <div className = "columns grafico">
                   <h1>Tenemos historias que contar</h1>
                   <div className = "video-container">
                    <iframe src="https://www.youtube.com/embed/YNrujHUR0ag"
                        title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; 
                        clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                        </iframe>
                    </div>
                </div>
                <div className = "texto">
                   <h1>¡Apoyános!</h1>
                    <p>
                      Conoce sobre el impacto que hemos tenido en Gracias a Dios.
                      Somos una red que se encuentra en constante crecimiento, sin embargo
                      enfretamos costos que diaremente superan nuestros objetivos.
                      Si quieres ser parte del cambio, puedes colaborar
                      aquí <a href="#">Link a Go Found Me</a>  
                    </p>
                 </div>
        </div>
    </section>
    )
}

export default Historias