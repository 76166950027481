import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Hero from "../components/contacto/HeroMain"
import Historias from "../components/contacto/Historias"
import Formulario from "../components/contacto/Formulario"
const IndexPage = (props) => (
  <Layout>
    <Seo title="contacto"/>
    <Hero/>
    <Historias/>
    <Formulario/>
  </Layout>
);

export default IndexPage;
