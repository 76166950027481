import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faHome } from '@fortawesome/free-solid-svg-icons'
import {faFacebookF, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons'

const Informacion = () => {
    return (
    <section id = "telesan-hero1">
            <p>Puedes contactarnos en:</p>
                <div className = "block">
                    <p>
                        <FontAwesomeIcon icon={faEnvelope} />
                        telesanhn@gmail.com
                    </p>
                </div>
                <div className = "block">
                    <p>
                        <FontAwesomeIcon icon={faPhone} />
                        +504 2231-1263
                    </p>
                </div>
                <div className = "block">
                    <p>
                        <FontAwesomeIcon icon={faHome} />
                        Edificio Paviole, ubicado en la colonia Alameda, 
                        avenida Julio Lozano Díaz, 11 calle, tegucigalpa M.D.C. 
                    </p>
                </div>
                <div className = "block">
                    <a href="https://www.facebook.com/TELESAN-Honduras-100759555532942" 
                    target= "_blank" rel = "noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a> 
                    <a href="https://www.instagram.com/telesan.hn/" target= "_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.youtube.com/channel/UC6aWS6EjKGVotD5Vby0kHsQ" target= "_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>

                </div> 
    </section>
    )
}

export default Informacion